import React, { useEffect } from 'react';
import { number, bool } from 'prop-types';

// RevealJS
import Reveal from 'reveal.js';
import HL from 'reveal.js/plugin/highlight/highlight';
import MD from 'reveal.js/plugin/markdown/markdown';
import RevealNotes from 'reveal.js/plugin/notes/notes';

// import Part1 from './part-1/Part';
// import Part2 from './part-2/Part';
import Part3 from './part-3/Part';
// import Part4 from './part-4/Part';
// import Part5 from './part-5/Part';
import { List } from './Generic';

function App(props) {
  const {
    width,
    height,
    margin,
    controls,
    slideNumber,
    history,
    mouseWheel
  } = props;

  useEffect(() => {
    Reveal.initialize({
      width,
      height,
      margin,
      controls,
      slideNumber,
      history,
      mouseWheel,
      transition: 'slide',
      fragmentInURL: false,
      backgroundTransition: 'zoom',
      navigationMode: 'linear',
      // keyboard: {
      //   38: 'next',
      //   40: 'prev'
      // },
      plugins: [HL, MD, RevealNotes]
    }).then(() => {
      try {
        const slideNumber = document.getElementsByClassName('slide-number')[0];
        slideNumber.addEventListener('click', () => Reveal.toggleOverview());
      } catch (e) {
        console.log(e);
      }
    });
  }, [width, height, margin, controls, slideNumber, history, mouseWheel]);

  return (
    <div className="reveal">
      <div className="slides">
        <section id="home">
          <List
            className=""
            list={[
              // <a href="#/part-1">
              //   Cours 1 : Présentation du module, prise en main de mi-lxc
              // </a>,
              // <a href="#/part-2">Cours 2 : ARP Spoofing</a>
              <a href="#/part-3">Cours 3 : HTTPS</a>
              // <a href="#/part-4">Cours 4 : Firewall linux</a>,
              // <a href="#/part-5">Cours 5 : Firewall pfSense</a>
            ]}
          />
        </section>
        {/* <Part1 /> */}

        {/* <Part2 /> */}
        <Part3 />
        {/*
        <Part4 />
        <Part5 /> */}
      </div>
    </div>
  );
}

App.defaultProps = {
  width: 1280,
  height: 720,
  margin: 0.05,
  controls: false,
  slideNumber: false,
  history: false,
  mouseWheel: false
};

App.propTypes = {
  width: number,
  height: number,
  margin: number,
  controls: bool,
  slideNumber: bool,
  history: bool,
  mouseWheel: bool
};

export default App;
